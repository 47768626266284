
import { defineComponent, ref, onMounted, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";
import { Event, EventItem } from "@/store/modules/EventModule"
import NewEventItemModal from "@/components/modals/forms/NewBoxItemInfoModal.vue"
import * as _ from 'lodash'

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    NewEventItemModal
  },
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("活动新增", ["活动管理;events"])
      } else {
        setCurrentPageBreadcrumbs("活动编辑", ["活动管理;events", route.params.id.toString()])
      }
    });

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const event = ref({} as Event)
    event.value.event_items_attributes = []

    const onCancel = () => {
      router.push({ name: "events" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            store
              .dispatch(
                Actions.CREATE_EVENT,
                event.value
              )
              .then(() => {
                router.push({ name: "events" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_EVENT, {
                id: route.params.id,
                values: event.value,
              })
              .then(() => {
                router.push({ name: "events" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          return false;
        }
      });
    };

    if (!isNew) {
      store
        .dispatch(Actions.GET_EVENT, route.params.id)
        .then(() => {
          event.value = store.getters.currentEvent
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const destroy_item = (item) => {
      item._destroy = 1
    }

    const add_event_items = (products) => {
      products.forEach((i) => {
        event.value.event_items_attributes.push({
          product_id: i.id,
          brand: i.brand,
          product: i.name,
          price: i.price,
          recycle_price: i.recycle_price,
          point: 1,
          limit_quantity: 0,
          stock: -1,
          sorting: event.value.event_items_attributes.length
        } as EventItem)
      })
    }

    const move_up = (item) => {
      if (item.sorting > 0) {
        item.sorting -= 1
      }

      let sorting_items = _.orderBy(event.value.event_items_attributes, 'sorting', 'asc').filter((i) => {
        return i != item
      })

      for(var i = 0; i < sorting_items.length; i++) {
        if (i >= item.sorting) {
          sorting_items[i].sorting = i + 1
        } else {
          sorting_items[i].sorting = i
        }
      }
    }

    const move_down = (item) => {
      if (item.sorting < event.value.event_items_attributes.length - 1) {
        item.sorting += 1
      }

      let sorting_items = _.orderBy(event.value.event_items_attributes, 'sorting', 'asc').filter((i) => {
        return i != item
      })

      for(var i = 0; i < sorting_items.length; i++) {
        if (i >= item.sorting) {
          sorting_items[i].sorting = i + 1
        } else {
          sorting_items[i].sorting = i
        }
      }
    }

    const orderedEventItems = () => {
      return _.orderBy(event.value.event_items_attributes, 'sorting', 'asc')
    }

    const current_role = ref('')

    if (store.getters.currentUser) {
      current_role.value = store.getters.currentUser.role
    }

    return {
      onSubmit,
      event,
      rules,
      onCancel,
      formRef,
      destroy_item,
      add_event_items,
      move_up,
      move_down,
      orderedEventItems
    }
  }
})
